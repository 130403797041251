import React, { useState } from "react";

import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import { BsTwitter, BsGithub, BsLinkedin } from "react-icons/bs";

import "./Footer.scss";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errState, setErrState] = useState(false);

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const contact = {
      _type: "contact",
      name: formData.name,
      email: formData.email,
      message: formData.message,
    };

    var emailReg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+.[a-zA-Z0-9]+$/;
    var emailRegRes = emailReg.test(contact.email);
    if (
      contact.name !== "" &&
      contact.email !== "" &&
      contact.message !== "" &&
      emailRegRes
    ) {
      client
        .create(contact)
        .then(() => {
          setLoading(false);
          setIsFormSubmitted(true);
          setErrState(false);
          setFormData({ name: "", email: "", message: "" });
        })
        .catch((err) => {
          setError(err);
        });
    } else {
      setLoading(false);
      setErrState(true);

      if (
        contact.name === "" ||
        contact.name === undefined ||
        contact.name === null
      ) {
        setError("Please enter your good name 😀.");
        return;
      }
      if (
        contact.email === "" ||
        contact.email === undefined ||
        contact.email === null
      ) {
        setError("Please enter your email address!");
        return;
      }
      if (emailRegRes === false) {
        setError("Enter valid email!");
        return;
      }
      if (
        contact.message === "" ||
        contact.message === undefined ||
        contact.message.length === null
      ) {
        setError("Please enter a nice message 😁.");
        return;
      }
    }
  };

  return (
    <>
      <h2 className="head-text">Take a coffee & chat with me</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:gurveensingh45@gmail.com" className="p-text">
            gurveensingh45@gmail.com
          </a>
        </div>
      </div>
      <div className="app__footer-form app__flex">
        <div className="app__flex">
          <input
            required
            className="p-text"
            type="text"
            placeholder="Your Name"
            name="name"
            value={name}
            onChange={handleChangeInput}
          />
        </div>
        <div className="app__flex">
          <input
            required
            className="p-text"
            type="email"
            placeholder="Your Email"
            name="email"
            value={email}
            onChange={handleChangeInput}
          />
        </div>

        <div>
          <textarea
            className="p-text"
            placeholder="Your Message"
            value={message}
            name="message"
            onChange={handleChangeInput}
          />
        </div>
        {errState ? <div className="app__error">{error}</div> : null}
        <button type="button" className="p-text" onClick={handleSubmit}>
          {!loading ? "Send Message" : "Sending..."}
        </button>
        {isFormSubmitted ? (
          <h3 className="head-text thanks-msg">
            Thank you for getting in touch!
          </h3>
        ) : null}
      </div>

      <div className="app__social-footer">
        <div>
          <a
            href="https://twitter.com/Schrodinger_dev"
            target="_blank"
            rel="noreferrer"
          >
            <BsTwitter />
          </a>
        </div>
        <div>
          <a
            href="https://github.com/Gurveen-Singh"
            target="_blank"
            rel="noreferrer"
          >
            <BsGithub />
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/in/gurveen-singh-bhatti"
            target="_blank"
            rel="noreferrer"
          >
            <BsLinkedin />
          </a>
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);
