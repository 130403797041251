/* eslint-disable import/no-anonymous-default-export */
import { images } from "../../constants";

export default {
  fields: [
    {
      title: "MyTube",
      desc: "A mini version of Youtube web app.",
      projectLink: "https://mytube-g.netlify.app/",
      codeLink: "https://github.com/Gurveen-Singh/MyTube",
      img: images.MyTube,
      tags: "Web App",
    },
    {
      title: "Stocky",
      desc: "A Crypto currency web app.",
      projectLink: "https://stocky-g.netlify.app/",
      codeLink: "https://github.com/Gurveen-Singh/stocky",
      img: images.Stocky,
      tags: "Web App",
    },
    {
      title: "Pig Game",
      desc: "Try IT!",
      projectLink: "https://pig-game-by-gurveen.netlify.app/",
      codeLink: "https://github.com/Gurveen-Singh/Pig-Game",
      img: images.PigGame,
      tags: "Web App",
    },
    {
      title: "Social Media App Design",
      desc: "A modern social media app design for new generations.",
      projectLink:
        "https://www.figma.com/proto/1iCc8gd614lWo0YFs32hTc/Prototyping-in-Figma?page-id=0%3A1&node-id=0%3A88&starting-point-node-id=0%3A2&scaling=scale-down",
      codeLink:
        "https://www.figma.com/proto/1iCc8gd614lWo0YFs32hTc/Prototyping-in-Figma?page-id=0%3A1&node-id=0%3A88&starting-point-node-id=0%3A2&scaling=scale-down",
      img: images.SocM,
      tags: "UI/UX",
    },
    {
      title: "Modern Bank Design",
      desc: "A modern Bank landing page web design.",
      projectLink:
        "https://www.figma.com/file/EIQ2DWxXgFUCwm7nWO3d57/Bank-Frame?node-id=103%3A84",
      codeLink:
        "https://www.figma.com/file/EIQ2DWxXgFUCwm7nWO3d57/Bank-Frame?node-id=103%3A84",
      img: images.BankFrame,
      tags: "UI/UX",
    },

    {
      title: "Car Information App Design",
      desc: "A modern car information app design for new generations.",
      projectLink:
        "https://www.figma.com/file/BleIk0h1jaUJ5QcuXE39tN/Dark-Neumorphism-UI-Tesla-app-(Community)?node-id=90%3A698",
      codeLink:
        "https://www.figma.com/file/BleIk0h1jaUJ5QcuXE39tN/Dark-Neumorphism-UI-Tesla-app-(Community)?node-id=90%3A698",
      img: images.Dark,
      tags: "UI/UX",
    },
  ],
};
