/* eslint-disable import/no-anonymous-default-export */
import { images } from "../../constants";

export default {
  fields: [
    {
      title: "What is Closure in JavaScript?",
      link: "https://gurveen.hashnode.dev/what-is-closure-in-javascript",
      img: images.blog1,
    },
  ],
};
