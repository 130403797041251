/* eslint-disable import/no-anonymous-default-export */

export default {
  fields: [
    {
      year: "2019 - 2020",

      works: [
        {
          role: "Web Developer",
          company: "Freelancer",
          desc: " While pursuing my graduation I did freelancing in the field of Web Development. I have made over 3+ user friendly websites for different business domain.",
        },
        {
          role: " Video Editor",
          company: "Freelancer",
          desc: " While pursuing my graduation I did freelancing in the field of Video Editing. I have made over 40+ videos for my clients.",
        },
      ],
    },
    {
      year: "2021 - 2022",

      works: [
        {
          role: "Research and Development Software Engineer",
          company: "Telaverge Communications",
          desc: "Developed Frontend technical solutions to meet project requirements for 5 different projects. Rewrote the code to the best knowm practices. Created the solution documents for various projects. Tested and debugged, and deployed the project to different customers. Mentored 3 new juniors on Frontend Development to expand JavaScript, React, and Development skillset.",
        },
      ],
    },
    {
      year: "2022 - Present",

      works: [
        {
          role: "Software Developer",
          company: "eQ Technologic",
          desc: "I am currently working here as a Software Developer.",
        },
      ],
    },
   
  ],
};
