/* eslint-disable import/no-anonymous-default-export */
import email from "../assets/email.webp";
import css from "../assets/css.webp";
import figma from "../assets/figma.webp";
import git from "../assets/git.webp";
import graphql from "../assets/graphql.webp";
import html from "../assets/html.webp";
import javascript from "../assets/javascript.webp";
import node from "../assets/node.webp";
import react from "../assets/react.webp";
import sass from "../assets/sass.webp";
import typescript from "../assets/typescript.webp";
import gojs from "../assets/gojs.webp";

import about01 from "../assets/about01.webp";
import about02 from "../assets/about02.webp";
import about03 from "../assets/about03.webp";
import about04 from "../assets/about04.webp";
import Hiemo from "../assets/Hiemo.webp";

import profile from "../assets/Profile.png";
import circle from "../assets/circle.svg";

import PigGame from "../assets/PigGame.webp";
import BankFrame from "../assets/BankFrame.webp";
import SocM from "../assets/SocM.webp";
import Dark from "../assets/Dark.webp";
import Stocky from "../assets/Stocky.webp";
import MyTube from "../assets/MyTube.webp";

import adParody from "../assets/adParody.webp";
import bhartiyeWebSeries from "../assets/bhartiyeWebSeries.webp";
import tv from "../assets/tV.webp";
import eQ from "../assets/eQLogo.webp";

import blog1 from "../assets/blog1.webp";

export default {
  email,
  css,
  figma,
  git,
  graphql,
  html,
  javascript,
  node,
  react,
  sass,
  typescript,gojs,
  about01,
  about02,
  about03,
  about04,
  Hiemo,
  profile,
  circle,
  MyTube,
  Stocky,
  PigGame,
  BankFrame,
  SocM,
  adParody,
  bhartiyeWebSeries,
  tv,eQ,
  Dark,
  blog1,
};
