/* eslint-disable import/no-anonymous-default-export */
import { images } from "../../constants";

export default {
  fields: [
    {
      title: "Frontend Development",
      desc: "I am a Frontend Developer with a passion for building beautiful and functional websites.",
      img: images.about01,
    },
    {
      title: "UI/UX Designer",
      desc: "I am a UI/UX Designer with a passion for creating beautiful UI that will make your client fall in love with it and of course you.",
      img: images.about02,
    },
    {
      title: "React Native Developer",
      desc: "I am a React Native Developer with a passion for building beautiful and functional mobile applications.",
      img: images.about03,
    },
  ],
};
