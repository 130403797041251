import React, { useState, useEffect } from "react";
import { AiFillEye } from "react-icons/ai";
import { motion } from "framer-motion";

import { BlogData, BrandsData } from "../../Data";
import { AppWrap, MotionWrap } from "../../wrapper";
import "./Blogs.scss";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    setBlogs(BlogData.fields);
    setBrands(BrandsData.fields);
  }, []);

  return (
    <>
      <>
        <h2 className="head-text">
          My <span>Blogs</span>
        </h2>

        <motion.div
          transition={{ duration: 0.5, delayChildren: 0.5 }}
          className="app__blog-portfolio"
        >
          {blogs.map((work, index) => (
            <div
              className="app__blog-item app__flex"
              key={index}
              style={{ maxWidth: "500px" }}
            >
              <div className="app__blog-img app__flex">
                <img src={work.img} alt={work.title} />

                <motion.div
                  whileHover={{ opacity: [0, 1] }}
                  whileTap={{ scale: 0.8 }}
                  transition={{
                    duration: 0.25,
                    ease: "easeInOut",
                    staggerChildren: 0.5,
                  }}
                  className="app__blog-hover app__flex"
                >
                  <a href={work.link} target="_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <AiFillEye />
                    </motion.div>
                  </a>
                </motion.div>
              </div>

              <div className="app__blog-content app__flex">
                <h3 className="bold-text">{work.title}</h3>
                <div className="app__blog-mob-link">
                  <a href={work.link} target="_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      transition={{ duration: 0.25 }}
                      className="app__flex"
                    >
                      <AiFillEye
                        className="eye-icon"
                        data-toggle="tooltip"
                        title="Live Demo"
                      />
                    </motion.div>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </motion.div>
      </>
      <div className="work-head">
        <h3 className="head-text">Where I've Worked</h3>
      </div>
      <div className="app__brands app__flex">
        {brands.map((brand) => (
          <motion.div
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 0.5, type: "tween" }}
            key={brand.name}
          >
            <img src={brand.img} alt={brand.name} />
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Blog, "app__blogs"),
  "blogs",
  "app__primarybg"
);
