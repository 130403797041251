/* eslint-disable import/no-anonymous-default-export */
import { images } from "../../constants";

export default {
  fields: [
    {
      name: "Ad Parody",
      img: images.adParody,
    },
    {
      name: "Bhartiye Web Series",
      img: images.bhartiyeWebSeries,
    },
    {
      name: "Telaverge Communications",
      img: images.tv,
    },
    {
      name: "eQ Technologic",
      img: images.eQ,
    },
  ],
};
